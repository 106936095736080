import React, { ReactNode } from "react";

export interface LocationSectionHeaderProps {
  className?: string;
  children?: ReactNode;
}

export const LocationSectionHeader = (props: LocationSectionHeaderProps) => {
  const { className = "", children } = props;
  return (
    <h4 className={`${className} text-xl mb-5 font-medium`}>{children}</h4>
  );
};
