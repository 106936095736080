import React, { ReactNode } from "react";
import { ListItem } from "./components/ListItem";

export interface ListProps {
  className?: string;
  items: string[] | ReactNode[];
  type?: "check" | "numbered" | "empty";
  space?: number;
}

export const List = (props: ListProps) => {
  const { className = "", type = "check", items, space = 4 } = props;
  return (
    <div className={`${className} flex flex-col space-y-${space}`}>
      {items.map((item, index) => (
        <ListItem type={type} key={index} number={index + 1} label={item} />
      ))}
    </div>
  );
};
